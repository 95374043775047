//MENU MOBILE
$(document).ready(function(e){
    //Mobile Menu
    $('.botao-menu').click( function (e) {
        $(".menu ul").toggleClass("show");
    });

    if($('#form_contato').length){

        $('#phone').mask("(00)00000-0000");

        $('#form_contato').on('submit', function(event){
            event.preventDefault();
            form = $('#form_contato');

            form.validate({
                messages: {
                    name: "Por favor, entre com um Nome válido.",
                    email: "Por favor, entre com um email válido.",
                    cpf: "CPF inválido ",
                    phone: "Telefone inválido ",
                }
            });

            $('#enviar-contato').attr('disabled', true);

            $.ajax({
                url: ajaxurl,
                type : 'post',
                data : {
                    action: 'festi_send_message',
                    office : $('input[name=office-unit]:checked').val(),
                    name : $('#name').val(),
                    email : $('#email').val(),
                    phone : $('#phone').val(),
                    message : $('#message').val()
                },
                success: function(response) {
                    try {
                        result = JSON.parse(response);
                        $('#retorno-msg').text(result.message);
                    } catch (e) {
                        console.error('Server response is not a JSON string.');
                        $('#retorno-msg').text('Ocorreu um erro ao tentar enviar sua mensagem, tente novamente mais tarde.');
                    }
                }
            }).always(function() {
                $('#enviar-contato').attr('disabled', false);
                form[0].reset();
            });

            return false;
        });
    }

    if($('#form_newsletter').length){
        $('#form_newsletter').on('submit', function(event) {

            event.preventDefault();
            form = $('#form_newsletter');

            $('#enviar-newsletter').attr('disabled', true);

            $.ajax({
                url: ajaxurl,
                type : 'post',
                data : {
                    action: 'festi_send_newsletter',
                    name : $('#name').val(),
                    email : $('#email').val()
                },
                success: function(response) {
                    try {
                        result = JSON.parse(response);
                        $('#retorno-msg').text(result.message);
                    } catch (e) {
                        console.error('Server response is not a JSON string.');
                        $('#retorno-msg').text('Ocorreu um erro ao tentar enviar sua mensagem, tente novamente mais tarde.');
                    }
                }
            }).always(function() {
                $('#enviar-newsletter').attr('disabled', false);
                form[0].reset();
            });

            return false;
        });
    }

    if($('#carousel-home').length){
        $('#carousel-home').owlCarousel({
            loop:true,
            autoplay: true,
            autoplayTimeout: 8000,
            margin:10,
            nav:true,
            items:1,
            autoHeight:true
        });
    }

    if($('#carousel-home-noticias').length){
        $('#carousel-home-noticias').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            items:3,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                480:{
                    items:2,
                    nav:false
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:false
                }
            }
        });
    }
});

function isSet(value) {
    return !(typeof value == 'undefined' || value == '' || value == null);
}

